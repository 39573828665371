function getKlaroCookie(name) {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? decodeURI(match[1]) : '';
}
function setKlaroCookie(name, consent, service) {
    var expires = "";
    var cdate = new Date();
    var value = '{"' + service + '":' + consent + '}';
    if (consent) {
        cdate.setTime(cdate.getTime() + (1095 * 1440 * 60 * 1000));
    } else {
        cdate.setHours(24, 0, 0, 0);
    }
    expires = "; expires=" + cdate.toUTCString();
    document.cookie = name + "=" + (encodeURI(value) || "") + expires + "; path=/";
}
function DoKlaroConsent(consent, service) {
    console.log('Klaro: DoKlaroConsent service: ' + service.name + ' consent: ' + consent);
    var value = getKlaroCookie('SprayCookieConsent');
    if (!consent) {
        if (value != '' && value != '{"GTM":true}') {
            setKlaroCookie('SprayCookieConsent', consent, service.name);
        }
    } else {
        if (value != '{"GTM":true}') {
            setKlaroCookie('SprayCookieConsent', consent, service.name);
        }
    }
    var consentText = "This website uses cookies. By continuing to browse or by clicking 'Accept' you agree to the storing of first and third party cookies on your device to enhance site navigation, analyze site usage and assist in our marketing efforts.";
    $.ajax({
        type: "POST",
        url: "/api/spray/PageContent/UpdateCookieAcceptance",
        data: {
            consentStatus: consent,
            appName: service,
            pagePath: window.location.href,
            userAgent: navigator.userAgent,
            consentText: consentText
        },
        content: "html",
        error: function () {
            console.log('Klaro: Failed to update cookie consent on server');
        },
        success: function () {
            console.log('Klaro: Cookie consent updated: ' + consent);
        }
    });
}

function DoKlaroCallback(countryCode, klaroConfig, EU_countries) {
    console.log("Klaro: Country code: " + countryCode);
    var isGDPR = false;
    if (EU_countries.includes(countryCode)) {
        isGDPR = true;
    }
    console.log("Klaro: isGDPR: " + isGDPR);

    if (isGDPR) {
        klaroConfig.default = false;
        klaroConfig.hideDeclineAll = false;
        klaroConfig.hideLearnMore = false;
        klaroConfig.services[0].default = false;
        klaroConfig.services[0].optOut = false;
    } else {
        klaroConfig.default = true;
        klaroConfig.hideDeclineAll = true;
        klaroConfig.hideLearnMore = true;
        klaroConfig.services[0].default = true;
        klaroConfig.services[0].optOut = true;
    }
    klaroConfig.callback = DoKlaroConsent;
    var script = document.createElement('script');
    script.setAttribute("data-config", "klaroConfig");
    script.type = 'text/javascript';
    script.src = '/scripts/klaro-v0.7.18.min.js';
    document.body.appendChild(script);
}

function DoKlaroInit(klaroConfig, EU_countries, clientIP) {
    jQuery.ajax({
        url: "/cdn-cgi/trace",
        headers: { 'cf-connecting-ip': clientIP },
        success: function (response) {
            var lines = response.split("\n");
            var shouldSkip = false;
            var keyValue;
            lines.forEach(function (line) {
                if (shouldSkip) {
                    return;
                }
                keyValue = line.split("=");
                if (keyValue[0] == "loc") {
                    shouldSkip = true;
                }
            });
            countryCode = decodeURIComponent(keyValue[1] || "");
            DoKlaroCallback(countryCode, klaroConfig, EU_countries);
        },
        error: function () {
            countryCode = "US";
            DoKlaroCallback(countryCode, klaroConfig, EU_countries);
        }
    });
}